import { Oval } from "react-loader-spinner";

const Loader = () => {
   return (
      <div className="container-fluid" style={{ border: 'solid rgb(192 192 192 / 61%) 1px', position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', background: 'rgb(192 192 192 / 61%)',zIndex:999,borderRadius:'12px'}}>
         <div className="container" style={{height:'100%'}}>
            <div className="row justify-content-center" style={{height:'100%',width:'100%',margin:'0px',display:'flex', alignItems: 'center', justifyContent: 'center' }}>
               <Oval
                  height={50}
                  width={50}
                  color="#1F428A"
                  wrapperStyle={{display:'flex',justifyContent:'center',alignItems:'center'}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="#fff"
                  strokeWidth={3}
                  strokeWidthSecondary={3}
               />
            </div>
         </div>
      </div>
   )
}

export default Loader;