import { HYDRATE } from 'next-redux-wrapper';
import { getMethod, postMethod } from '../../utils/apiServices';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
export const getMasterDataList = createAsyncThunk('filters/getMasterDataList', async ({ key, value, masterListVar, releventQuery }) => {
    const response = await postMethod(`web-data?key=${key}&masterListVar=${masterListVar}&value=${value?.trim()}`, { releventQuery: releventQuery ? releventQuery : '' }, {});
    return (response.data)
});

export const getSearchProviders = createAsyncThunk('filters/getSearchProvider', async ({ body }) => {
    const response = await postMethod('doctors/search', body, {});
    return (response.data)
});

export const getFacilityLocation = createAsyncThunk('filters/getFacilityLocation', async ({ body, params }) => {
    const response = await getMethod('facilities/search', params);
    return (response.data)
});

export const getSingleDoctor = createAsyncThunk('filters/getSingleDoctor', async ({ slug }) => {
    const response = await getMethod(`web-providers/${slug}`, {});
    return (response.data)
});
export const getSingleFacility = createAsyncThunk('filters/getSingleFacility', async ({ slug }) => {
    const response = await getMethod(`web-facilities/${slug}`, {});
    return (response.data)
});
export const postDataList = createAsyncThunk('filters/postDataList', async ({ page, limit, modifledObject }) => {
    const response = await postMethod(`apply-filter?page=${page}&limit=${limit}`, modifledObject, {});
    return (response.data);
});
const initformValue = {
    selectFor: '',
    selectCSCType: '',
    zipcode: '',
    keyword: '',
    county: '',
    state: '',
    city: '',
    hospital: '',
    specialties: '',
    gender: '',
    languages: '',
}
const initMasterData = {
    countyList: [],
    stateList: [],
    zipCodeList: [],
    cityList: [],
    hospitalList: [],
    specialtiesList: [],
    genderList: [],
    languagesList: []
}
const initList = {
    list: [],
    total: 0,
    page: 0,
    limit: 10,
    key: '',
    selectedCard: '',
}
const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        formSelected: "advancefillter",
        formFields: initformValue,
        globalLoader: false,
        masterData: initMasterData,
        listData: initList,
        singleDoctorData: {}
    },
    reducers: {
        setFormType: (state, actions) => {
            state.formSelected = actions.payload;
        },
        setUpdateFormField: (state, action) => {
            const oldState = JSON.parse(JSON.stringify(state));
            var { formFields } = oldState;
            const { key, value } = action.payload
            formFields[key] = value;
            state.formFields = formFields;
        },
        setUpdateAllFormField: (state, action) => {
            state.formFields = action.payload;
        },
        setClearAllFormData: (state, action) => {
            state.formFields = initformValue;
        },
        setUpdateGlobalLoader: (state, action) => {
            state.globalLoader = action.payload;
        },
        setUpdatePushList: (state, action) => {
            const { list, key, valueData } = action.payload;
            const modiedValue = ([...list, { value: valueData, label: valueData }]);
            state.masterData[key] = modiedValue?.length ? ((Array.from(new Set(modiedValue.map((obj) => obj.value))).map((value) => modiedValue?.find((obj) => obj?.value === value)))) : []
        },
        setSelectedCardUpdate: (state, action) => {
            state.listData.selectedCard = action.payload
        },
        setClearListData: (state, action) => {
            state.listData = initList
        },
        setUpdateDataDoctorPage: (state, action) => {
            state.singleDoctorData = action.payload;
            state.globalLoader = false;
        }

    },
    extraReducers: {
        [getMasterDataList.pending]: (state, action) => {
            state.globalLoader = false
            state.listData.key = action?.meta?.arg?.key ? action?.meta?.arg?.key : ""
        },
        [getMasterDataList.fulfilled]: (state, action) => {
            if (action.payload) {
                state.globalLoader = false;
                state.listData.key = ""
                let { list, masterListVar } = action.payload;
                if (list?.length) {
                    state.masterData[masterListVar] = list
                }
            }
        },
        [getMasterDataList.rejected]: (state, action) => {
            state.globalLoader = false
            state.listData.key = ""

        },
        [postDataList.pending]: (state, action) => {
            state.globalLoader = true
            if (action?.meta?.arg?.page === 0) {
                state.listData.list = [],
                    state.listData.total = 0,
                    state.listData.page = 0,
                    state.listData.limit = 10
            }
        },
        [postDataList.fulfilled]: (state, action) => {
            const { listData: { list } } = JSON.parse(JSON.stringify(state));
            state.globalLoader = false
            if (!list?.length) {
                state.listData.list = action?.payload?.list ? action?.payload?.list : []
            } else {
                state.listData.list = list.concat(action?.payload?.list)
            }
            state.listData.total = action?.payload?.total ? action?.payload?.total : 0,
                state.listData.page = action?.payload?.page ? action?.payload?.page : 0,
                state.listData.limit = action?.payload?.limit ? action?.payload?.limit : 10
        },
        [postDataList.rejected]: (state, action) => {
            state.globalLoader = false

        },

        [getSingleDoctor.pending]: (state, action) => {
            state.globalLoader = true;
            state.singleDoctorData = {}

        },
        [getSingleDoctor.fulfilled]: (state, action) => {
            state.globalLoader = false;
            state.singleDoctorData = action.payload

        },
        [getSingleDoctor.rejected]: (state, action) => {
            state.globalLoader = false;
            state.singleDoctorData = {}

        },


        [getSingleFacility.pending]: (state, action) => {
            state.globalLoader = true;
            state.singleDoctorData = {}

        },
        [getSingleFacility.fulfilled]: (state, action) => {
            state.globalLoader = false;
            state.singleDoctorData = action.payload

        },
        [getSingleFacility.rejected]: (state, action) => {
            state.globalLoader = false;
            state.singleDoctorData = {}

        }
    }
});

export const { setFormType, setUpdateAllFormField, setClearListData, setSelectedCardUpdate, setUpdateFormField, setUpdateDataDoctorPage, setClearAllFormData, setUpdateGlobalLoader, setUpdatePushList } = filterSlice.actions;

export default filterSlice.reducer;