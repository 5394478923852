import Loader from '@/components/Loader';
import { persistor, store } from '@/store';
import '@/styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Head from "next/head";
import Script from "next/script";
import Header from '@/components/SearchForm/components/header/Header';
import Footer from '@/components/SearchForm/components/footer/Footer';
const App = ({ Component, pageProps }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
        <Script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW"
          crossOrigin="anonymous"
        />
        <Header />
        <Component {...pageProps} />
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          containerClassName="toastContainer toaster-wrapper123"
          toastOptions={{
            className: 'defaulterrormessage',
            duration: 6000,
            success: {
              duration: 5000,
            }
          }}
        />
        <Footer />
      </PersistGate>
    </Provider>

  )
}

export default App;