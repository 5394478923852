import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
const Logo = require("../../../../public/assets/LibertyMedicareAdvantageLogo_CMYK.png");
import Image from "next/image";
import { clsx } from "clsx";
import Link from "next/link";
import { setClearAllFormData, setFormType } from "@/store/slices/filterSlice";
import { useDispatch } from "react-redux";
export default function Header() {
  const dispatch = useDispatch()
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div className={clsx(styles.header, "fixed-top bg-white")}>
      <div className={clsx(styles.contact_navbar)}>
        <div>
          <a href="tel:+18333541498">
            {" "}
            <span> Potential Members </span>
            <br /> <b>(833) 354-1498</b>
          </a>
        </div>
        <div>
          {" "}
          <a href="tel:+18448546884">
            {" "}
            <span> Member & Provider Services </span> <br />
            <b> (844) 854-6884 </b>
          </a>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link href="/" passHref legacyBehavior>
            <a className="navbar-brand" onClick={(e)=>{
              dispatch(setFormType("advancefillter"))
              dispatch(setClearAllFormData(""))

              }}>
              <Image src={Logo} alt="Logo" className={styles.logo} />
            </a>
          </Link>
          <div className={styles.nav_toggle_btn}>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className={styles.navbar_div}>
              <ul className="navbar-nav">
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/brokers/"
                  >
                    Agents
                  </a>
                </li>
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/member-resources/"
                  >
                    Members
                  </a>
                </li>
                <li className={clsx(styles.nav_link, "nav-item dropdown")}>
                  {windowSize.width <= 992 ? (
                    <>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Plans
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        className="nav-link d-inline-block"
                        href="https://www.libertyadvantageplan.com/plans/"
                      >
                        Plans
                      </a>
                      <span
                        className="dropdown-toggle d-inline-block"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></span>
                    </>
                  )}

                  <ul
                    className={clsx(
                      styles.dropdown_menubar,
                      "dropdown-menu border-0 p-0"
                    )}
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-advantage-nursing-home-plan/"
                      >
                        2023 Liberty Medicare Advantage Nursing Home Plan
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-advantage-plan/"
                      >
                        2023 Liberty Medicare Advantage Plan
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/plans/2023-liberty-medicare-dual-plan/"
                      >
                        2023 Liberty Medicare Dual Plan
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/plans/"
                      >
                        Plan Archive
                      </a>
                    </li>
                  </ul>
                </li>{" "}
                <li className={clsx(styles.nav_link, "nav-item dropdown")}>
                  {windowSize.width <= 992 ? (
                    <>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Provider
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        className="nav-link d-inline-block"
                        href="https://www.libertyadvantageplan.com/providers-partners/"
                      >
                        Provider
                      </a>
                      <span
                        className="dropdown-toggle d-inline-block"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></span>
                    </>
                  )}

                  <ul
                    className={clsx(
                      styles.dropdown_menubar,
                      "dropdown-menu border-0 p-0"
                    )}
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/providers-partners/"
                      >
                        Providers & Partners
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.libertyadvantageplan.com/provider-pharmacy-formulary-search/"
                      >
                        Pharmacy & Formulary Search
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={clsx(styles.nav_link, "nav-item")}>
                  <a
                    className="nav-link"
                    href="https://www.libertyadvantageplan.com/request-consultation/"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
